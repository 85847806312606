import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/func/Home.vue'
import { userStore } from '@/stores/user'
import { useGlobalStore } from '@/stores/global'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  base: import.meta.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/func/Login.vue'),
      meta: {
        withoutAuth: true,
        hideinfo: true
      }

    },
    {
      path: '/password-setting',
      name: 'PasswordSetting',
      component: () => import('@/views/func/PasswordSetting.vue'),
      meta: {
      }
    },
    {
      path: '/faq',
      name: 'Faq',
      component: () => import('@/views/func/Faq.vue'),
      meta: {
        hideFooter: true,
        hideLang: true,
        withoutAuth: true

      }
    },
    {
      path: '/forget',
      name: 'Forget',
      component: () => import('@/views/func/Forget.vue'),
      meta: {
        withoutAuth: true,
        hideinfo: true
      }
    },
    {
      path: '/password-reset',
      alias: ['/forget/set.php'],
      name: 'ResetPassword',
      component: () => import('@/views/func/PasswordReset.vue'),
      meta: {
        withoutAuth: true,
        hideinfo: true
      }
    },
    {
      path: '/otp-signup',
      name: 'Otp Signup',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('@/views/func/OtpSignup.vue'),
    },
    {
      path: '/register',
      name: 'Register',
      component: () => import('@/views/func/Register.vue'),
      meta: {
        withoutAuth: true,
        hideinfo: true
      }
    },
    {
      path: '/subscribe',
      name: 'Subscribe',
      component: () => import('@/views/func/Subscribe.vue'),
    },
    {
      path: '/totp',
      name: 'Totp',
      component: () => import('@/views/func/Totp.vue'),
      meta: {
        withoutAuth: true,
        hideinfo: true
      }
    },

    {
      path: '/verify',
      name: 'Verify',
      component: () => import('@/views/func/Verify.vue'),
    },

    {
      path: '/topup',
      name: 'Topup',
      component: () => import('@/views/func/Topup.vue'),
    },
    {
      path: '/topup/return.php',
      name: 'TopupReturn',
      component: () => import('@/views/func/TopupReturn.vue'),
    },
    // -- 以下尚未完成
    /*
*/

    {
      path: '/:pathMatch(.*)',
      redirect: { name: 'home' }
    }, // Catch-all route for 404

  ]
})

router.beforeResolve((to, from, next) => {
  const user = userStore()
  const global = useGlobalStore()

  // 统一管理登录状态
  const isLoggedIn = user.isLogined()
  global.$patch({ isLogin: isLoggedIn })

  if (to.meta.withoutAuth) {
    // 已登录用户访问登录页时重定向到首页
    if (isLoggedIn && to.path === '/login') {
      return next('/')
    }
    return next()
  }

  if (!isLoggedIn) {
    const redirect = to.fullPath
    // 防止XSS：检查重定向URL
    if (redirect && (redirect.includes('javascript:') || redirect.startsWith('//') || redirect.startsWith('http'))) {
      return next('/login')
    }
    return next({
      path: '/login',
      query: { redirect: encodeURIComponent(redirect) }
    })
  }

  next()
})

export default router
