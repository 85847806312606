<script>
import { changeLocale } from '@/utils/locale.js';

export default {
    data() {
        return {
            show: false,
            langs: {
                'en': 'English',
                'ja': '日本語',
                'zh-TW': '繁體中文',
                'zh-CN': '簡體中文',
                'fr': 'français',
                'de': 'Deutsch',
                'es': 'Español',
                'pt': 'Português',
                "th": "ภาษาไทย",
            },
            lang: "zh-TW",
        };
    },
    methods: {
        setLang(lang) {
            changeLocale(lang);
        },
    }
}
</script>
<template>
    <div class="language" 
         v-if="!$route.meta.hideLang" 
         @mouseenter="show = true" 
         @mouseleave="show = false">
        <dl>
            <dt class="language-main">
                <span class="icon">
                    <svg id="icon-globel" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                        viewBox="0 0 20 20">
                        <g fill="#666">
                            <path id="Path_1251" data-name="Path 1251"
                                d="M10,0a10,10,0,1,0,10,10A10.009,10.009,0,0,0,10,0m8.588,9.308H14.824a14.584,14.584,0,0,0-1.931-7.425,8.634,8.634,0,0,1,5.692,7.425M10,18.615c-1.56,0-3.289-3.222-3.446-7.928h6.892c-.156,4.706-1.885,7.928-3.446,7.928M6.551,9.308C6.707,4.6,8.437,1.38,10,1.38s3.29,3.222,3.446,7.928ZM7.1,1.882a14.584,14.584,0,0,0-1.93,7.425H1.409A8.636,8.636,0,0,1,7.1,1.882M1.409,10.688H5.171A14.585,14.585,0,0,0,7.1,18.113a8.636,8.636,0,0,1-5.692-7.426m11.484,7.426a14.584,14.584,0,0,0,1.931-7.426h3.761a8.635,8.635,0,0,1-5.692,7.426" />
                        </g>
                    </svg>
                </span>
                <span>{{ $t("language.select") }}</span>
                <span class="icon">
                    <svg id="icon-arrow" xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10"
                        fill="#666">
                        <rect id="Rectangle_8" data-name="Rectangle 8" width="4.827" height="0.965" rx="0.483"
                            transform="translate(3.579 0) rotate(45)" />
                        <rect id="Rectangle_11" data-name="Rectangle 11" width="4.827" height="0.965" rx="0.483"
                            transform="translate(2.897 9.135) rotate(-45)" />
                        <rect id="Rectangle_9" data-name="Rectangle 9" width="4.827" height="0.965" rx="0.483"
                            transform="translate(4.096 0.683) rotate(135)" />
                        <rect id="Rectangle_10" data-name="Rectangle 10" width="4.827" height="0.966" rx="0.483"
                            transform="translate(3.414 9.818) rotate(-135)" />
                    </svg>
                </span>
            </dt>
            <dd class="language-list" v-show="show">
                <ul>
                    <li v-for="(name, lang) in langs" :key="lang">
                        <a @click="setLang(lang)">{{ name }}</a>
                    </li>
                </ul>
            </dd>
        </dl>
    </div>
</template>